.help-sidebar {
	@apply text-xs leading-normal;
	.content {
		h2 {
			@apply text-base mt-6 mb-2;
			min-height: initial;
		}
		a:not(.btn) {
			@apply text-primary no-underline my-4;
			&:hover {
				@apply underline opacity-80;
			}
			&:focus-within {
				@apply outline-none;
			}
		}
		a.btn {
			@apply py-1 px-2 m-1 min-w-fit text-inherit no-underline;
			font-size: inherit;
			&:hover {
				@apply opacity-80;
			}
		}
		ol {
			@apply ps-4 my-2 list-decimal;
		}
		ul {
			@apply ps-4 my-2 list-disc;
		}
		li,
		p {
			@apply mb-2;
		}
	}
}
