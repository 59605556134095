@use "./variables" as *;

.main-content {
	height: 100vh;
	overflow: auto;
	max-height: calc(100vh - $topbar-height);
	height: calc(100vh - $topbar-height);
	.description:not(:empty) {
		// 3 rows is aprox. 3 times the line height;
		min-height: calc(3 * $line-height * 1em);
		white-space: pre-line;
		font-size: 0.95rem;
	}
}

.view {
	height: 100%;
	max-height: 100%;
	display: flex;
	flex: 1 1 auto;
	flex-direction: column;
	overflow-x: auto;
	@apply pt-6 px-6 pb-4 gap-4;
	.header {
		@apply flex gap-2 flex-wrap items-center;
	}
	.content {
		height: 100%;
		max-height: 100%;
		overflow: hidden;
		@apply flex flex-col gap-4;
		& > h2 {
			margin: 0;
		}
	}
	.actions {
		margin-top: 1rem;
	}
}

.modal {
	.modal-content {
		h2 {
			margin: 0;
		}
	}
}

.aside {
	width: 300px;
	min-width: 300px;
}
